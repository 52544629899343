<pv-drawer
  [isVisible]="showVoucherRedemptionModal"
  [showCloseIcon]="false"
  [headerTemplate]="headerTemplate"
  [contentTemplate]="contentTemplate"
  [footerTemplate]="footerTemplate"
  [showBackdrop]="true"
  [dismissOnBackdropClick]="true"
  [closeOnEscape]="true"
  [style]="pvDrawerConfig"
  [showCloseIcon]="true"
  class="voucherRedemptionModal"
  (closeHandler)="handleVoucherRedemptionModal(false)"
  [ngClass]="">
  <ng-template #headerTemplate>
    <div class="text-2xl font-bold">Claiming Voucher</div>
  </ng-template>

  <ng-template #contentTemplate>
    <span class="text-base font-normal mt-3 flex">
      You can only avail one voucher.
    </span>
    <div
      class="flex mt-3 flex-row card-border justify-content-between gift-card-container p-2">
      <div class="w-3 flex">
        <img [src]="gvDetails?.imageUrl" alt="voucher" class="w-full h-full" />
      </div>
      <div class="w-7 flex flex-column pl-2 align-self-center">
        <span class="text-gray-900 pv-text-20px font-bold pv-line-clamp-1">
          {{ gvDetails?.merchantName }}
        </span>
        <span class="text-gray-500 pv-text-14px font-normal pv-line-clamp-2">
          {{ gvDetails?.title }}
        </span>
      </div>
    </div>
  </ng-template>

  <ng-template #footerTemplate>
    <div class="flex flex-column">
      <span class="text-red-400 mb-2" *ngIf="isRedemptionFailed">
        Failed to redeem, want to retry ?
      </span>

      <div class="flex gap-1">
        <pv-button
          variant="text"
          class="w-full sm:mt-0 mt-3 flex justify-content-around"
          [style]="claimButtonStyle"
          (clickEvent)="goToDetailsPage()"
          label="View Details"></pv-button>

        <pv-button
          *ngIf="isRedemptionFailed; else ClaimCta"
          variant="contained"
          class="w-full sm:mt-0 mt-3 flex justify-content-around"
          [style]="retryButtonStyle"
          (clickEvent)="handleRedeem()"
          [isDisabled]="isRedeeming"
          [isLoading]="isRedeeming"
          label="Retry"></pv-button>
        <ng-template #ClaimCta>
          <pv-button
            variant="contained"
            class="w-full sm:mt-0 mt-3 flex justify-content-around"
            [style]="claimButtonStyle"
            [isLoading]="isRedeeming"
            [isDisabled]="isRedeeming"
            (clickEvent)="handleRedeem()"
            label="Claim"></pv-button>
        </ng-template>
      </div>
    </div>
  </ng-template>
</pv-drawer>
<pv-drawer
  [isVisible]="showVoucherClaimModal"
  [showCloseIcon]="false"
  [headerTemplate]="claimHeaderTemplate"
  [contentTemplate]="claimContentTemplate"
  [footerTemplate]="claimFooterTemplate"
  [showBackdrop]="true"
  [dismissOnBackdropClick]="true"
  [closeOnEscape]="true"
  [style]="pvDrawerConfig"
  [showCloseIcon]="false"
  (closeHandler)="showVoucherClaimModal = false"
  [ngClass]="">
  <ng-template #claimHeaderTemplate></ng-template>

  <ng-template #claimContentTemplate>
    <div class="flex mt-2 flex-column justify-content-center">
      <div class="flex justify-content-center mt-4">
        <pv-svg-icon name="tick" [style]="tickStyle"></pv-svg-icon>
      </div>
      <span class="text-gray-900 text-2xl align-self-center font-bold mt-2">
        Voucher Claimed!
      </span>
      <span class="text-gray-900 mt-3">
        Voucher code and instructions have been sent to you registered mobile
        number.
      </span>
    </div>
  </ng-template>

  <ng-template #claimFooterTemplate>
    <div class="flex gap-1">
      <pv-button
        variant="contained"
        class="w-full sm:mt-0 mt-3 flex justify-content-around"
        [style]="claimButtonStyle"
        (clickEvent)="goToDetailsPage()"
        label="View Code"></pv-button>
    </div>
  </ng-template>
</pv-drawer>
