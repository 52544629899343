import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { TokenExpiredConfig } from '@pv-frontend/authentication';
import {
  JOURNEY_METADATA_SERVICE_TOKEN,
  JourneyMetadataService,
} from '@pv-frontend/pv-shared-services/journey-metadata-service';
import {
  PV_USER_SERVICE,
  PvUserService,
} from '@pv-frontend/pv-shared-services/pv-user-service';
import { Subject, takeUntil } from 'rxjs';

import {
  CampSegment,
  CampSegmentType,
  Campaign,
  RedemptionDetails,
  UserAccountDetails,
} from './interfaces/gv-redemption';
import { CampaignService } from './services/campaign/campaign.service';

@Component({
  selector: 'hdfc-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy {
  private destroy$: Subject<void> = new Subject();
  public showAuthModal: boolean = false;
  public showRedemptionModal: boolean = false;
  public redemptionObj?: RedemptionDetails;
  public tokenExpiredModuleConfig?: Partial<TokenExpiredConfig>;
  private readonly TokenExpiredConfigPath: string = 'journey.tokenExpired';
  public showTokenExpiredModal: boolean = false;
  public userSegment?: CampSegmentType;
  public CampSegment = CampSegment;

  @ViewChild('authContainer', { read: ElementRef, static: false })
  public authContainer: ElementRef | undefined;
  public observer: MutationObserver | undefined;
  public showConsentMessage: boolean = false;

  public observeDOMChanges(): void {
    if (this.authContainer) {
      const targetNode = this.authContainer.nativeElement;
      if (targetNode) {
        this.observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.type === 'childList') {
              const pvMobileSearch =
                targetNode.querySelector('pv-mobile-search');
              this.showConsentMessage = !!pvMobileSearch;
            }
          });
        });

        const config = { childList: true, subtree: true };
        this.observer.observe(targetNode, config);
      }
    }
  }

  public constructor(
    @Inject(PV_USER_SERVICE) private pvUserService: PvUserService,
    @Inject(JOURNEY_METADATA_SERVICE_TOKEN)
    private journeyMetadataService: JourneyMetadataService,
    private campaignService: CampaignService
  ) {
    this.setTokenExpiredModalConfig(this.TokenExpiredConfigPath);
    this.subscribeToGvRedemption();
    this.subscribeToTokenExpiry();
    this.subscribeUserDetails();
    this.setUserSegment();
    this.pvUserService.loginModalObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (showAuthModal: boolean) => {
          this.showAuthModal = showAuthModal;
          if (this.showAuthModal) {
            setTimeout(() => {
              this.observeDOMChanges();
            }, 0);
          }
        },
      });
  }

  private setTokenExpiredModalConfig(pathToView: string): void {
    this.tokenExpiredModuleConfig =
      this.journeyMetadataService.getJourneyConfig(
        pathToView
      ) as TokenExpiredConfig;
  }

  private subscribeToGvRedemption(): void {
    this.campaignService.redeemGv
      .pipe(takeUntil(this.destroy$))
      .subscribe((redemptionObj: RedemptionDetails | false) => {
        if (redemptionObj as RedemptionDetails) {
          this.redemptionObj = redemptionObj as RedemptionDetails;
          this.showRedemptionModal = true;
        } else {
          this.redemptionObj = undefined;
          this.showRedemptionModal = false;
        }
      });
  }

  private subscribeToTokenExpiry(): void {
    this.pvUserService.tokenExpiredObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (isExpired: boolean) => {
          if (isExpired && !this.showTokenExpiredModal) {
            this.pvUserService.logoutUser();
            this.showTokenExpiredModal = isExpired;
          }
        },
      });
  }

  private subscribeUserDetails(): void {
    this.pvUserService.userLoggedInObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (isLoggedIn: boolean) => {
          if (!isLoggedIn) {
            this.userSegment = undefined;
          }
        },
      });

    this.campaignService.userAccountDetails
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAccDetails: UserAccountDetails) => {
        this.userSegment = userAccDetails.segment?.toLowerCase() ?? '';
      });
  }

  private setUserSegment(): void {
    const campaignDetails: Campaign | null =
      this.campaignService.getStoredCampaignDetails();
    if (!campaignDetails) return;
    const campaignNameArr = campaignDetails?.attributes?.name?.split(' - ');
    this.userSegment = (campaignNameArr?.[1] as CampSegmentType)?.toLowerCase();
  }

  public handleOpenAuthModal(): void {
    this.showTokenExpiredModal = false;
    this.pvUserService.toggleAuthModal(true);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
