<footer>
  <div
    class="flex justify-content-between p-5 sm:w-6 sm:mx-auto sm:flex-row flex-column align-items-center">
    <img
      src="https://images.poshvine.com/hdfc-salary/HDFC-Bank-Reverse-Logo.png"
      class="hdfc-logo mb-3 sm:mb-0 shadow-1"
      alt="hdfc" />
    <div class="flex flex-column align-items-end">
      <span
        class="flex sm:text-sm flex-wrap justify-content-center sm:justify-content-end">
        For queries, please write to
        <a
          class="text-primary font-semibold ml-1"
          href="mailto:hdfc-poshvine@razorpay.com">
          hdfc-poshvine&#64;razorpay.com
        </a>
      </span>
      <div
        class="flex mt-3 sm:mt-2 flex-wrap justify-content-center sm:justify-content-end w-full sm:w-auto">
        <a
          class="text-primary font-semibold"
          target="_blank"
          [href]="termsAndConditionsExternal">
          Terms and Conditions
        </a>
        <a
          class="text-primary font-semibold ml-3"
          target="_blank"
          href="/info/privacy-policy">
          Privacy Policy
        </a>
      </div>
    </div>
  </div>
  <div class="border-1 w-full justify-content-center flex p-3 surface-border">
    <img
      src="https://images.poshvine.com/common/pci-dss.svg"
      alt="pci"
      class="pci" />
  </div>
  <div
    class="flex pv-mt-22px font-light align-items-center justify-content-center mb-5 powered-text font-normal">
    Powered by
    <a class="razorpay-logo" href="http://razorpay.com/" target="_blank">
      <img
        alt="razorpay-logo"
        class="razorpay-logo pv-ml-7px md:pv-ml-5px"
        src="https://images.poshvine.com/razorpay/razorpay_blue_logo.svg" />
    </a>
  </div>
</footer>
