import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import {
  BookingResponse,
  Campaign,
  GiftCardBookingResponse,
  GiftCardRedemptionDetailsResponse,
  GiftCardRedemptionOptionsResponse,
  GvDetailsFlatObj,
  GcRedemptionDetail,
  RedemptionDetails,
  UserAccountDetails,
  MembershipRedemptionDetail,
  MembershipRedemptionOptionsResponse,
  REDEMPTION_TYPE,
  MembershipRedemptionDetailsResponse,
  MembershipRedemptionResponse,
  RedemptionType,
  GcAttributes,
  MembershipDetailsAttributes,
} from '../../interfaces/gv-redemption';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  public redeemGv: EventEmitter<RedemptionDetails | false> = new EventEmitter();
  public redeemedVoucher: EventEmitter<string> = new EventEmitter();
  public userAccountDetails: EventEmitter<UserAccountDetails> =
    new EventEmitter();

  public constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private currencyPipe: CurrencyPipe
  ) {}

  public getCampaignGiftcardRedemptions(
    campaignId: string
  ): Observable<GiftCardRedemptionOptionsResponse> {
    return this.http.get<GiftCardRedemptionOptionsResponse>(
      environment.campaignPath +
        `campaigns/` +
        campaignId +
        `/gift_card_redemption_options`
    );
  }

  public getCampaignMembershipRedemptions(
    campaignId: string
  ): Observable<MembershipRedemptionOptionsResponse> {
    return this.http.get<MembershipRedemptionOptionsResponse>(
      environment.campaignPath +
        `campaigns/` +
        campaignId +
        `/membership_redemption_options`
    );
  }

  public formatCurrency(currency: number, symbol: string = 'symbol'): string {
    return (
      this.currencyPipe.transform(currency, 'INR', symbol, '1.0', 'en-IN') ?? ''
    );
  }

  private createGvDetailsCommon(
    gvDetails: GcAttributes | MembershipDetailsAttributes,
    redemptionOptionId: string,
    isClaimed: boolean,
    type: RedemptionType,
    redemptionValue: number
  ): GvDetailsFlatObj {
    const merchantName = gvDetails?.merchant?.name || 'Unknown Merchant';
    return {
      id: redemptionOptionId,
      imageUrl:
        gvDetails?.image_url ??
        'https://images.poshvine.com/icon/gv/rectangular-image-placeholder.jpeg',
      title: `Complimentary ${merchantName} ${type === REDEMPTION_TYPE.GIFTCARD ? 'giftcard' : 'membership'} worth ${this.formatCurrency(redemptionValue)}`,
      isClaimed: isClaimed,
      merchantName: merchantName,
      description: gvDetails?.description,
      voucherDetails: {},
      redemption_steps: gvDetails?.redemption_steps,
      terms: gvDetails?.terms ?? '',
      giftCardId: type === REDEMPTION_TYPE.GIFTCARD ? gvDetails?.id : undefined,
      membershipId:
        type === REDEMPTION_TYPE.MEMBERSHIP ? gvDetails?.id : undefined,
      redemptionUrl:
        type === REDEMPTION_TYPE.GIFTCARD
          ? (gvDetails as GcAttributes)?.redemption_url
          : ((gvDetails as MembershipDetailsAttributes)?.membership_url ??
            gvDetails?.merchant.website),
      type: type,
    };
  }

  public createGvDetails(giftcard: GcRedemptionDetail): GvDetailsFlatObj {
    const giftcardDetails =
      giftcard?.attributes?.gift_card_details.data?.attributes;
    const isClaimed = this.isGiftcardClaimed(giftcard);
    return this.createGvDetailsCommon(
      giftcardDetails,
      giftcard.attributes.gift_card_redemption_option.data.id,
      isClaimed,
      REDEMPTION_TYPE.GIFTCARD,
      giftcard.attributes.redemption_value
    );
  }

  public createGvDetailsForMembership(
    membership: MembershipRedemptionDetail
  ): GvDetailsFlatObj {
    const membershipDetails =
      membership?.attributes?.membership_details.data?.attributes;
    const isClaimed = this.isMembershipClaimed(membership);
    return this.createGvDetailsCommon(
      membershipDetails,
      membership.attributes.membership_redemption_option.data.id,
      isClaimed,
      REDEMPTION_TYPE.MEMBERSHIP,
      membership.attributes.redemption_value
    );
  }

  public redeemGiftCard(
    campaignId: string,
    gvRedemptionId: string
  ): Observable<GiftCardBookingResponse> {
    return this.http.post<GiftCardBookingResponse>(
      `${environment.campaignPath}campaigns/${campaignId}/gift_card_redemption_options/${gvRedemptionId}/redeem`,
      {}
    );
  }

  public redeemMembership(
    campaignId: string,
    gvRedemptionId: string
  ): Observable<MembershipRedemptionResponse> {
    return this.http.post<MembershipRedemptionResponse>(
      `${environment.campaignPath}campaigns/${campaignId}/membership_redemption_options/${gvRedemptionId}/redeem`,
      {}
    );
  }

  public getCampaignGiftcardDetails(
    campaignId: string,
    gvRedemptionId: string
  ): Observable<GiftCardRedemptionDetailsResponse> {
    return this.http.get<GiftCardRedemptionDetailsResponse>(
      `${environment.campaignPath}campaigns/${campaignId}/gift_card_redemption_options/${gvRedemptionId}`
    );
  }

  public getCampaignMembershipDetails(
    campaignId: string,
    gvRedemptionId: string
  ): Observable<MembershipRedemptionDetailsResponse> {
    return this.http.get<MembershipRedemptionDetailsResponse>(
      `${environment.campaignPath}campaigns/${campaignId}/membership_redemption_options/${gvRedemptionId}`
    );
  }

  public getCampaignDetails(
    campaignId: string
  ): Observable<{ data: Campaign }> {
    return this.http.get<{ data: Campaign }>(
      `${environment.campaignPath}/campaigns/${campaignId}`
    );
  }

  public showErrorMessage(message: string): void {
    this.messageService.add({
      severity: 'error',
      summary: message,
    });
  }

  public isGiftcardClaimed(giftcard: GcRedemptionDetail): boolean {
    return (
      !giftcard.attributes.campaign_claimable.claimable &&
      giftcard.attributes.campaign_claimable.non_claimable_reason ===
        'already claimed!'
    );
  }

  public isMembershipClaimed(membership: MembershipRedemptionDetail): boolean {
    return (
      !membership.attributes.campaign_claimable.claimable &&
      membership.attributes.campaign_claimable.non_claimable_reason ===
        'already claimed!'
    );
  }

  public getCampaignRedemptions(
    campaignId: string
  ): Observable<BookingResponse> {
    return this.http.get<BookingResponse>(
      environment.campaignPath + `/user_redemptions`,
      {
        params: {
          campaign_ids: [campaignId],
          status: 'success',
        },
      }
    );
  }

  public getStoredCampaignDetails(): Campaign | null {
    return JSON.parse(localStorage.getItem('campaignDetails') ?? 'null');
  }
}
