import { CurrencyPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import {
  PvAuthenticationModule,
  PvTokenExpiredModule,
} from '@pv-frontend/authentication';
import { PvButtonModule } from '@pv-frontend/pv-shared-components/button';
import { PvDrawerModule } from '@pv-frontend/pv-shared-components/drawer';
import { SvgIconModule } from '@pv-frontend/pv-shared-components/svg-icon';
import { JOURNEY_METADATA_SERVICE_TOKEN } from '@pv-frontend/pv-shared-services/journey-metadata-service';
import { PV_USER_SERVICE } from '@pv-frontend/pv-shared-services/pv-user-service';
import * as Sentry from '@sentry/angular';
import { MessageService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';

import { JourneyMetaDataServiceImpl } from '../../journey-metadata-impl.service';
import { PvUserServiceImpl } from '../../user-service-impl.service';
import { ErrorInterceptor } from '../interceptors/error.interceptor';
import { TokenInterceptor } from '../interceptors/token.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from './environments/environment';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { RedemptionModalComponent } from './redemption-modal/redemption-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    RedemptionModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    PvButtonModule,
    ToastModule,
    BrowserAnimationsModule,
    SvgIconModule,
    PvAuthenticationModule,
    PvDrawerModule,
    PvTokenExpiredModule,
    CheckboxModule,
  ],
  providers: [
    {
      provide: PV_USER_SERVICE,
      useExisting: PvUserServiceImpl,
    },
    {
      provide: JOURNEY_METADATA_SERVICE_TOKEN,
      useExisting: JourneyMetaDataServiceImpl,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: 'env',
      useValue: environment,
    },
    {
      provide: 'environment',
      useValue: environment,
    },
    MessageService,
    PvUserServiceImpl,
    JourneyMetaDataServiceImpl,
    CurrencyPipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
      useFactory: (): unknown => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
