<div
  class="relative overflow-scroll h-screen"
  [ngClass]="{
    'gold-segment': userSegment === CampSegment.GOLD,
    'platinum-segment': userSegment === CampSegment.PLATINUM
  }">
  <hdfc-header class="absolute top-0 left-0 w-full"></hdfc-header>

  <div class="w-full inner-scroll overflow-scroll">
    <router-outlet></router-outlet>

    <hdfc-footer></hdfc-footer>
  </div>
</div>

<pv-authentication
  #authContainer
  *ngIf="showAuthModal"
  [isVisible]="showAuthModal">
  <div
    externalHeaderTemplate
    class="auth-header flex w-full justify-content-center">
    <img
      src="https://images.poshvine.com/hdfc-salary/HDFC-Bank-Reverse-Logo.png"
      class="logo m-auto"
      alt="hdfc" />
  </div>
  <div externalFooterTemplate>
    <div class="consent-checkbox my-2 text-start" *ngIf="showConsentMessage">
      <p-checkbox class="mr-1" [binary]="true"></p-checkbox>
      <span for="consent" class="pv-text-11px text-black-alpha-60">
        I hereby consent to the processing of my data for sending me
        personalized offers on other products and services of HDFC Bank, its
        affiliates, and partners through Call, SMS, WhatsApp, Email, or other
        channels in the manner described in the notice
        <a
          href="https://images.poshvine.com//app/HDFC_Other+Products.docx"
          class="font-bold">
          here.
        </a>
      </span>
    </div>

    <span
      class="auth-footer w-full justify-content-center pv-text-11px block text-center text-black-alpha-60">
      Please refer to our
      <a href="/info/terms-and-conditions" class="font-bold">
        Terms and Conditions
      </a>
      &
      <a href="/info/privacy-policy" class="font-bold">Privacy Policy</a>
    </span>
  </div>
</pv-authentication>

<hdfc-redemption-modal
  *ngIf="showRedemptionModal"
  [campaignId]="redemptionObj?.campaignId"
  [gvDetails]="redemptionObj?.gv"></hdfc-redemption-modal>

<pv-token-expired
  [moduleConfig]="tokenExpiredModuleConfig"
  [show]="showTokenExpiredModal"
  (openAuthModal)="handleOpenAuthModal()"></pv-token-expired>
