import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { OverrideStyleConfig } from '@pv-frontend/pv-shared-components/common/types';
import { Observable, Subject, takeUntil } from 'rxjs';

import {
  BookingResponse,
  GiftCardBookingResponse,
  GiftCardOrder,
  GvDetailsFlatObj,
  MembershipRedemptionResponse,
  REDEMPTION_TYPE,
} from '../interfaces/gv-redemption';
import { CampaignService } from '../services/campaign/campaign.service';
import { DeviceService } from '../services/device-service/device.service';

@Component({
  selector: 'hdfc-redemption-modal',
  templateUrl: './redemption-modal.component.html',
  styleUrls: ['./redemption-modal.component.scss'],
})
export class RedemptionModalComponent implements OnDestroy {
  @Input() public campaignId?: string;
  @Input() public gvDetails?: GvDetailsFlatObj;
  public isRedeeming: boolean = false;
  public showVoucherRedemptionModal: boolean = true;
  public showVoucherClaimModal: boolean = false;
  public claimButtonStyle: OverrideStyleConfig = {
    height: '3rem',
    width: '136px',
  };
  public retryButtonStyle: OverrideStyleConfig = {
    ...this.claimButtonStyle,
    backgroundColor: 'var(--red-600)',
  };
  public destroy$: Subject<void> = new Subject();
  public isRedemptionFailed: boolean = false;
  private giftcardRedemptionDetails?: GiftCardOrder;
  private pollingInterval: number = 1000; // in milliseconds
  private maxPollingTime: number = 120000; // 2 minutes in milliseconds

  public pvDrawerConfig: OverrideStyleConfig = {
    borderRadius: '15px',
    overflow: 'hidden',
    padding: '1.5rem 2rem 2rem 2rem',
    minWidth: this.deviceService.isCurrentDeviceMobile() ? '100%' : '400px',
    maxHeight: '322px !important',
  };

  public tickStyle: OverrideStyleConfig = {
    height: '50px',
    width: '50px',
    fill: 'var(--green)',
  };

  public constructor(
    private router: Router,
    private campaignService: CampaignService,
    private deviceService: DeviceService
  ) {}

  public goToDetailsPage(): void {
    this.router.navigateByUrl(
      `/details/${this.campaignId}/${this.gvDetails?.type}/${this.gvDetails?.id}`
    );
    this.showVoucherRedemptionModal = false;
    this.campaignService.redeemGv.emit(false);
  }

  public handleRedeem(): void {
    if (this.isRedeeming) return;
    if (!this.campaignId || !this.gvDetails?.id) return;
    this.isRedeeming = true;

    const redeem$: Observable<
      GiftCardBookingResponse | MembershipRedemptionResponse
    > =
      this.gvDetails.type === REDEMPTION_TYPE.GIFTCARD
        ? this.campaignService.redeemGiftCard(
            this.campaignId,
            this.gvDetails.id
          )
        : this.campaignService.redeemMembership(
            this.campaignId,
            this.gvDetails.id
          );

    redeem$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (
        redemptionResponse:
          | GiftCardBookingResponse
          | MembershipRedemptionResponse
      ) => {
        this.handleRedemptionResponse(redemptionResponse);
      },
      error: () => {
        this.handleRedemptionError();
      },
    });
  }

  private handleRedemptionResponse(
    response: GiftCardBookingResponse | MembershipRedemptionResponse
  ): void {
    if ('errors' in response) {
      this.handleRedemptionError();
      return;
    }

    if (this.gvDetails?.type === REDEMPTION_TYPE.GIFTCARD) {
      this.processGiftCardResponse(response as GiftCardBookingResponse);
    } else {
      this.processMembershipResponse(response as MembershipRedemptionResponse);
    }
  }

  private processGiftCardResponse(response: GiftCardBookingResponse): void {
    const gvRedemptionDetails =
      response.data?.attributes?.gift_card_orders?.[0] ?? {};

    if (
      (gvRedemptionDetails?.status === 'success' ||
        gvRedemptionDetails?.status === 'complete') &&
      gvRedemptionDetails.vouchers?.length
    ) {
      this.giftcardRedemptionDetails = gvRedemptionDetails;
      this.getVoucher();
      this.isRedemptionFailed = false;
    } else {
      this.handleRedemptionError();
    }
  }

  private processMembershipResponse(
    response: MembershipRedemptionResponse
  ): void {
    const membershipDetails = response.data?.attributes ?? {};

    if (membershipDetails.state === 'claimed' && membershipDetails.code) {
      this.isRedemptionFailed = false;
      this.showVoucherRedemptionModal = false;
      this.campaignService.redeemedVoucher.emit(membershipDetails.code);
      this.showVoucherClaimModal = true;
    } else {
      this.handleRedemptionError();
    }
  }

  private handleRedemptionError(): void {
    this.isRedemptionFailed = true;
    this.isRedeeming = false;
  }

  private pollingReqToCheckVoucher(): void {
    if (this.pollingInterval <= this.maxPollingTime) {
      setTimeout(() => {
        this.getVoucher();
      }, this.pollingInterval);

      // Double the polling interval for the next attempt
      this.pollingInterval *= 2;
    } else {
      this.isRedeeming = false;
      this.isRedemptionFailed = true;
    }
  }

  private getVoucher(): void {
    if (!this.campaignId) return;
    this.isRedeeming = true;
    this.campaignService
      .getCampaignRedemptions(this.campaignId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (bookings: BookingResponse) => {
          if (bookings.gift_card_bookings?.data?.length) {
            const redeemedGvVoucher =
              bookings.gift_card_bookings?.data?.filter((g) =>
                g.attributes.gift_card_orders.find(
                  (order) =>
                    order.gift_card_id ===
                    this.giftcardRedemptionDetails?.gift_card_id
                )
              )?.[0].attributes?.gift_card_orders?.[0] ?? {};

            if (redeemedGvVoucher?.vouchers?.length) {
              this.isRedeeming = false;
              this.showVoucherRedemptionModal = false;
              this.showVoucherClaimModal = true;
              this.campaignService.redeemedVoucher.emit(
                redeemedGvVoucher?.vouchers[0].code ??
                  redeemedGvVoucher?.vouchers[0].pin ??
                  ''
              );
            } else {
              this.pollingReqToCheckVoucher();
            }
          } else {
            this.pollingReqToCheckVoucher();
          }
        },
        error: () => {
          this.handleRedemptionError();
        },
      });
  }

  public handleVoucherRedemptionModal(openModal: boolean): void {
    this.showVoucherRedemptionModal = openModal;
    this.campaignService.redeemGv.emit(false);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
