import { ValueOf } from '@pv-frontend/pv-shared-services/common';

export interface Campaign {
  id: string;
  type: string;
  attributes: CampaignAttributes;
  campaign_rules: CampaignRules;
  campaign_active_incentive: CampaignActiveIncentive;
  events: EventContainer;
  redeemable_options: string[];
  redemption_options: RedemptionOptions;
  eligibility_expiry_date: string | null;
  claimable: boolean;
  accumulated_rewards: AccumulatedRewards;
  valid_bins: (string | null)[];
  merchants_info: MerchantsInfo;
  benefit_state: string;
  payment_enabled: boolean;
  amount_to_pay: number;
  campaign_eligible_user_id: string;
  eligibility_renewals: unknown[];
  referral_campaign: unknown;
}

interface CampaignAttributes {
  name: string;
  client_id: string;
  project_id: string;
  approval_status: string;
  import_status: string;
  start_date: string | null;
  end_date: string | null;
  version_number: number;
  run_status: string;
  request_url_suffix: string;
}

interface CampaignRules {
  data: CampaignRule[];
}

interface CampaignRule {
  id: string;
  type: string;
  attributes: RuleAttributes;
}

interface RuleAttributes {
  id: string;
  type: string;
  rule_details: RuleDetails;
}

interface RuleDetails {
  data: RuleDetailData;
}

interface RuleDetailData {
  id: string;
  type: string;
  attributes: RuleDetailAttributes;
}

interface RuleDetailAttributes {
  id: string;
  criterion_join_type: string;
  profile_rule_criterion_groups?: CriterionGroups;
  demography_rule_criterions?: CriterionGroups;
  event_rule_criterions?: CriterionGroups;
  template_event_id?: string | null;
  data_imports?: unknown;
}

interface CriterionGroups {
  data: CriterionGroup[];
}

interface CriterionGroup {
  id: string;
  type: string;
  attributes: CriterionGroupAttributes;
}

interface CriterionGroupAttributes {
  id: string;
  created_at: string;
  profile_rule_criterions: Criterion[];
}

interface Criterion {
  data: CriterionAttributes[];
}

interface CriterionAttributes {
  id: string;
  type: string;
  created_at: string;
  modifiable: boolean;
  display_name: string;
  duration_type: string;
  duration: string;
  display_title: string | null;
  display_description: string | null;
  fields_metadata: FieldsMetadata;
}

interface FieldsMetadata {
  type: MetadataField;
  amount_quantity?: MetadataField;
  quantity_variation?: MetadataField;
  duration_type: MetadataField;
  duration: MetadataField;
}

interface MetadataField {
  required: boolean;
  displayed: boolean;
  allowed_type: string;
  allowed_values: string | string[];
}

interface CampaignActiveIncentive {
  data: CampaignIncentive[];
}

interface CampaignIncentive {
  id: string;
  type: string;
  attributes: IncentiveAttributes;
}

interface IncentiveAttributes {
  id: string;
  status: string;
  incentive_details: IncentiveDetails;
}

interface IncentiveDetails {
  data: IncentiveDetailData;
}

interface IncentiveDetailData {
  id: string;
  type: string;
  attributes: IncentiveDetailAttributes;
}

interface IncentiveDetailAttributes {
  id: string;
  benefits_value: string | null;
  status: string;
}

interface EventContainer {
  data: CustomEvent[];
}

interface CustomEvent {
  id: string;
  type: string;
  attributes: EventAttributes;
}

interface EventAttributes {
  id: string;
  name: string;
  named_event_type: string | null;
  event_rule_criterions: Criterion;
}

interface RedemptionOptions {
  data: RedemptionOption[];
  included: GiftCardRedemptionOption[];
}

interface RedemptionOption {
  id: string;
  type: string;
  attributes: RedemptionOptionAttributes;
  relationships: RedemptionRelationships;
}

interface RedemptionOptionAttributes {
  id: string;
  campaign_id: string;
}

interface RedemptionRelationships {
  eligiblity_renewals: { data: unknown[] };
  redeemable: { data: RedeemableData };
}

interface RedeemableData {
  id: string;
  type: string;
}

interface GiftCardRedemptionOption {
  id: string;
  type: string;
  attributes: GiftCardAttributes;
}

interface GiftCardAttributes {
  id: string;
  gift_card_id: string;
  price_type: string;
  min_price: number | null;
  max_price: number | null;
  denominations: number[];
}

interface AccumulatedRewards {
  accumulated_points: AccumulatedPoints;
  user_points: Record<string, unknown>;
}

interface AccumulatedPoints {
  total_accumulated_rewards: number;
  claimed_rewards: number;
}

interface MerchantsInfo {
  GiftCardRedemptionOption: (string | null)[];
}

export interface GiftcardRedemptionResponse<T> {
  data: T;
  meta: Meta;
}

export type GiftCardRedemptionOptionsResponse = GiftcardRedemptionResponse<
  GcRedemptionDetail[]
>;

export type GiftCardRedemptionDetailsResponse =
  GiftcardRedemptionResponse<GcRedemptionDetail>;

export interface GcRedemptionDetail {
  id: string;
  type: 'gift_card_redemption_detail';
  attributes: GcRedemptionAttributes;
}

interface GcRedemptionAttributes {
  id: string;
  gift_card_details: GiftCardDetails;
  gift_card_redemption_option: GiftCardRedemptionOption;
  redemption_type: string;
  redemption_value: number;
  campaign_claimable: CampaignClaimable;
}

interface GiftCardDetails {
  data: GiftCardData;
}

interface GiftCardData {
  id: string;
  type: 'gift_card';
  attributes: GcAttributes;
}

export interface GcAttributes {
  id: string;
  title: string;
  description: string;
  terms: string;
  redemption_steps: string;
  redemption_type: string[];
  poshvine_commission: null | number;
  is_sclp: boolean;
  card_type: string;
  price_type: string;
  popular: boolean;
  denominations: number[];
  min_price: number | null;
  max_price: number | null;
  end_date: string;
  redemption_url: string;
  image_url: string;
  max_quantity_per_order: number;
  merchant: Merchant;
  provider: Provider;
  categories: Category[];
  claimable: boolean;
  discount: null | number;
}

interface Merchant {
  id: string;
  name: string;
  logo: null | string;
  website: string;
  description: string;
}

interface Provider {
  id: string;
  name: string;
  logo: ProviderLogo;
  config: ProviderConfig;
  benefits_count: number;
  benefit_type: string[];
  currency_mapping: Record<string, unknown>;
}

interface ProviderLogo {
  name: string;
  record: LogoRecord;
}

interface LogoRecord {
  id: string;
  id_v1: null | string;
  name: string;
  token: string;
  secret: string;
  token_expires_at: null | string;
  benefits_count: number;
  benefit_type: string[];
  poshvine_commission: null | number;
  config: RecordConfig;
  currency_mapping: Record<string, unknown>;
  created_at: string;
  updated_at: string;
  display_name: null | string;
  client_id: null | string;
}

interface RecordConfig {
  import_via_api: boolean;
  max_item_in_cart: string;
  product_max_qty_allow: string;
}

interface ProviderConfig {
  import_via_api: boolean;
  max_item_in_cart: string;
  product_max_qty_allow: string;
}

interface Category {
  id: string;
  name: string;
  parent_id: null | string;
}

interface GiftCardRedemptionOption {
  data: RedemptionOptionData;
}

interface RedemptionOptionData {
  id: string;
  type: 'gift_card_redemption_option';
  attributes: GiftCardAttributes;
}

interface CampaignClaimable {
  claimable: boolean;
  non_claimable_reason: null | string;
}

interface Meta {
  user_campaign_point_details: UserCampaignPointDetails;
}

interface UserCampaignPointDetails {
  user_current_campaign_points: number;
  user_campaign_points: number;
  user_campaign_redemptions: number;
}

export const REDEMPTION_TYPE = {
  GIFTCARD: 'giftcard',
  MEMBERSHIP: 'membership',
} as const;
export type RedemptionType = ValueOf<typeof REDEMPTION_TYPE>;

export interface GvDetailsFlatObj {
  id: string;
  imageUrl: string;
  title: string;
  merchantName: string;
  description: string;
  isClaimed?: boolean;
  voucherDetails?: unknown; // TODO: need to add type for this
  terms: string;
  redemption_steps: string | null;
  giftCardId?: string;
  membershipId?: string;
  redemptionUrl: string;
  type: RedemptionType;
}

export interface GiftCardOrder {
  gift_card_id: string;
  denomination: string;
  quantity: number;
  status: string;
  api_resp: unknown; // assuming unknown type since the structure is not specified
  recipient_info: Record<string, unknown>; // assuming unknown type since the structure is not specified
  order_no: string | null;
  provider_order_no: string | null;
  redemption_ids: string[];
  poshvine_commission: number;
  gift_card_title: string;
  gift_card_image: string;
  merchant: Merchant;
  vouchers: Voucher[];
  is_sclp: boolean;
  tnc: string;
  redemption_steps: string;
  redemption_type: string[];
  redemption_url: string;
}

interface Attributes {
  user_card_id: string | null;
  status: string;
  created_at: string;
  gift_card_orders: GiftCardOrder[];
  unlocked_benefit_orders: unknown[];
}

export interface GiftCardBookingResponse {
  data: GiftCardBooking;
  unlocked_benefit_orders?: {
    data: unknown;
  };
}

export interface RedemptionDetails {
  campaignId: string;
  gv: GvDetailsFlatObj;
}

interface Voucher {
  card_number: string;
  pin: string | null;
  denomination: number;
  code: string;
  validity: string;
  activation_url: string | null;
  id: string;
}

export interface BookingResponse {
  offer_redemptions: unknown[];
  membership_redemptions: MembershipBooking[];
  referral_redemptions: unknown[];
  gift_card_bookings: {
    data: GiftCardBooking[];
  };
}

interface GiftCardBooking {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface UserAccountDetails {
  salaryCredit: SalaryCredit;
  segment: CampSegmentType;
}

export const CREDIT_TYPE = {
  FIRST: '1st credit',
  SECOND: '2nd credit',
  THIRD: '3rd credit',
} as const;
export type SalaryCredit = ValueOf<typeof CREDIT_TYPE>;
export const CampSegment = {
  PLATINUM: 'platinum',
  GOLD: 'gold',
};
export type CampSegmentType = ValueOf<typeof CampSegment>;

export interface MembershipResponse<T> {
  data: T;
  meta: Meta;
}

export type MembershipRedemptionOptionsResponse = MembershipResponse<
  MembershipRedemptionDetail[]
>;

export type MembershipRedemptionDetailsResponse =
  MembershipResponse<MembershipRedemptionDetail>;

export interface MembershipRedemptionDetail {
  id: string;
  type: 'membership_redemption_detail';
  attributes: MembershipRedemptionAttributes;
}

export interface MembershipDetailsAttributes {
  id: string;
  categories: Category[];
  claimable: boolean;
  cost_price: number | null;
  description: string;
  end_date: string;
  image_url: string | null;
  live?: boolean;
  membership_type: string;
  membership_url: string | null;
  merchant: Merchant;
  original_price: number;
  poshvine_commission: number;
  redemption_steps: string | null;
  source: string;
  start_date: string;
  terms: string | null;
  title: string;
  updated_at: string;
}

interface MembershipRedemptionAttributes {
  id: string;
  membership_details: {
    data: {
      id: string;
      type: string;
      attributes: MembershipDetailsAttributes;
    };
  };
  membership_redemption_option: {
    data: {
      id: string;
      type: 'membership_redemption_option';
      attributes: {
        membership_id: string;
        merchant_id: string;
        id: string;
      };
    };
  };
  redemption_type: string;
  redemption_value: number;
  campaign_claimable: CampaignClaimable;
}

export interface MembershipRedemptionResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      code: string;
      pin: string | null;
      state: string;
      voucher_end_date: string;
      redemption_id: string;
      order_no: string;
    };
  };
  redemption_url: string;
  order_value: number;
  unlocked_benefit_orders: {
    data: unknown[];
  };
}

export interface MembershipBooking {
  id: string;
  type: string;
  attributes: {
    created_at: string;
    order_value: number;
    campaign_id: string;
    gift_card_booking_id: string | null;
    merchant: Merchant;
    gift_card_order: object;
    offer: object;
    gift_card: object;
    membership: {
      id: string;
      title: string;
      end_date: string;
      image: string;
      redemption_link: string;
    };
    voucher: object;
    gift_card_voucher: object;
    membership_voucher: {
      id: string;
      code: string;
      status: string;
      pin: string | null;
      card_number?: string;
      voucher_end_date: string;
    };
    card_used: object;
  };
}
