import { EnvInterface } from '../interfaces/environment.types';

export const environment: EnvInterface = {
  production: true,
  sentryDsn:
    'https://f3bb80d129874ed3297c9fd2274745d9@o515678.ingest.us.sentry.io/4507425650966528',
  apiPerksServiceUrl: 'https://api-customer.poshvine.com/ps/v1',
  apiPerksServiceUrlV2: 'https://api-customer.poshvine.com/ps/v2',
  apiClientsServiceUrl: 'https://api-customer.poshvine.com/cs/v1',
  apiUrlPaV1: 'https://api-customer.poshvine.com/pa/v1/',
  apiBookingServiceUrl: 'https://api-customer.poshvine.com/bs/v1',
  clientId: 'd04da69e-8bff-4ce9-8f6a-1ecf76beb02f',
  projectId: '566a02e9-e8ad-4202-986a-1f7444592943',
  apiUrlBsV1: 'https://api-customer.poshvine.com/bs/v1/',
  apiUrlBsV1Internal: 'https://api-customer.poshvine.com/bs/internal',
  apiUrlLsV1: 'https://api-customer.poshvine.com/ls/v1/',
  corePath: 'https://api-customer-offerservice.poshvine.com/core_service/v1',
  corePathV2: 'https://api-customer.poshvine.com/cs/v1',
  offerPath: 'https://api-customer-offerservice.poshvine.com/offer_service/v1',
  offerPathV2: 'https://api-customer.poshvine.com/ps/v1',
  countryId: '625a3e8e-e493-421a-8ece-609f10b37442',
  urlsToBeRemoved: [''],
  apiLoyaltyServiceUrl: 'https://api-customer.poshvine.com/ls/v1',
  campaignPath: 'https://api-customer.poshvine.com/cas/v1/',
  apiBookingServiceUrlV2: '',
  projectLink: 'http://hdfcspecialesalary.poshvine.com/',
};
