<div
  class="header-container flex align-items-center p-3 w-full"
  [ngClass]="{
    'md:justify-content-start justify-content-center': !isLoggedIn
  }">
  <button
    (click)="handleBackToHomePage()"
    class="p-0 border-none m-0 flex border-0 logo-container cursor-pointer">
    <img
      src="https://images.poshvine.com/hdfc-salary/HDFC-Bank-Reverse-Logo.png"
      class="logo"
      alt="hdfc" />
  </button>

  <a
    class="flex align-items-center"
    href="https://www.hdfcbank.com/personal/save/accounts/salary-account/speciale-salary-account"
    target="_blank">
    <div
      class="flex align-items-center"
      *ngIf="!isLoggedIn; else PostLoginSegmentLogo">
      <img
        src="https://images.poshvine.com/hdfc-salary/specialplatinumLogo.svg"
        alt=""
        class="platinum-logo mb-2 ml-3" />
      <img
        src="https://images.poshvine.com/hdfc-salary/specialGoldLogo.png"
        alt=""
        class="gold-logo ml-3" />
    </div>
  </a>

  <ng-template #PostLoginSegmentLogo>
    <ng-container [ngSwitch]="userSegment">
      <img
        *ngSwitchCase="CampSegment.PLATINUM"
        src="https://images.poshvine.com/hdfc-salary/specialplatinumLogo.svg"
        alt=""
        class="platinum-logo mb-2 ml-3" />
      <img
        *ngSwitchCase="CampSegment.GOLD"
        src="https://images.poshvine.com/hdfc-salary/specialGoldLogo.png"
        alt=""
        class="gold-logo ml-3" />
    </ng-container>
  </ng-template>

  <pv-button
    label="Logout"
    styleClass="flex flex-row-reverse gap-2"
    *ngIf="isLoggedIn"
    (clickEvent)="handleLogout()"
    class="ml-auto">
    <pv-svg-icon
      name="exit"
      [style]="{
        width: '20px',
        height: '22px',
        fill: 'var(--surface-a)'
      }"></pv-svg-icon>
  </pv-button>
</div>
