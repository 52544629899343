import { Component, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  PV_USER_SERVICE,
  PvUserService,
} from '@pv-frontend/pv-shared-services/pv-user-service';
import { Subject, takeUntil } from 'rxjs';

import {
  CampSegment,
  CampSegmentType,
  UserAccountDetails,
} from '../interfaces/gv-redemption';
import { CampaignService } from '../services/campaign/campaign.service';

@Component({
  selector: 'hdfc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  public isLoggedIn: boolean = true;
  private destroy$: Subject<void> = new Subject<void>();
  public userSegment?: CampSegmentType;
  public CampSegment = CampSegment;

  public constructor(
    @Inject(PV_USER_SERVICE) private pvUserService: PvUserService,
    private router: Router,
    private campaignService: CampaignService
  ) {
    this.subscribeUserLogin();
    this.subscribeUserDetails();
  }

  public handleBackToHomePage(): void {
    this.router.navigate(['']);
  }

  public handleLogout(): void {
    this.pvUserService.logoutUser();
    this.handleBackToHomePage();
  }

  private subscribeUserLogin(): void {
    this.isLoggedIn = this.pvUserService.isUserAuthenticated();
    this.pvUserService.userLoggedInObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
        if (!this.isLoggedIn) {
          this.userSegment = undefined;
        }
      });
  }

  private subscribeUserDetails(): void {
    this.campaignService.userAccountDetails
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAccDetails: UserAccountDetails) => {
        this.userSegment = userAccDetails.segment?.toLowerCase() ?? '';
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
