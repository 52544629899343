import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./homepage/homepage.module').then((m) => m.HomepageModule),
  },
  {
    path: 'details/:campaignId/:redemptionType/:id',
    loadChildren: () =>
      import('./gv-details/gv-details.module').then((m) => m.GvDetailsModule),
  },
  {
    path: 'sso_login',
    loadChildren: () =>
      import('@pv-frontend/sso-login').then((m) => m.SSOModule),
  },
  {
    path: 'info',
    loadChildren: () => import('./info/info.module').then((m) => m.InfoModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
