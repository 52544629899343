import { Component } from '@angular/core';

@Component({
  selector: 'hdfc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public termsAndConditionsExternal: string =
    'https://www.hdfcbank.com/content/bbp/repositories/723fb80a-2dde-42a3-9793-7ae1be57c87f/?path=/Personal/Save/Accounts/Salary%20Account/Speciale%20Salary%20Account/Speciale_Salary_Account_Gold_and_Platinum.pdf';
}
